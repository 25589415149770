<template>
  <section class="invoice-add-wrapper">
    <b-breadcrumb class="mb-1">
      <b-breadcrumb-item href="/">
        <b-icon
          icon="house-fill"
          scale="1.25"
          shift-v="1.25"
          aria-hidden="true"
        ></b-icon>
        Dashboard
      </b-breadcrumb-item>
      <b-breadcrumb-item v-on:click="goBack"
        >Facture récurrentes</b-breadcrumb-item
      >
      <b-breadcrumb-item active>Nouvelle Facture</b-breadcrumb-item>
    </b-breadcrumb>
    <b-row class="invoice-add">
      <b-col cols="12" xl="9" md="8">
        <b-card>
          <b-card no-body class="invoice-preview-card">
            <b-card-body class="invoice-padding pb-0">
              <div
                class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
              >
                <!-- Header: Left Content -->
                <div>
                  <div class="logo-wrapper">
                    <b-img
                      :src="enterprise.logo"
                      width="64"
                      alt="placeholder"
                    ></b-img>
                    <h3 class="text-primary invoice-logo">
                      {{ enterprise.name }}
                    </h3>
                  </div>

                  <b-card-text class="mb-25">
                    {{ enterprise.address }} - {{ enterprise.country.name }}
                  </b-card-text>
                </div>

                <!-- Header: Right Content -->
                <div class="invoice-number-date mt-md-0 mt-2">
                  <div
                    class="d-flex align-items-center justify-content-md-end mb-1"
                  >
                    <h4 class="invoice-title">Facture N°</h4>
                    <b-form-group
                      class="input-group-merge invoice-edit-input-group disabled"
                    >
                      <b-form-input
                        readonly
                        v-model="invoice.num"
                        id="invoice-data-id"
                      />
                    </b-form-group>
                  </div>
                  <div
                    class="d-flex align-items-center justify-content-md-end mb-1"
                  >
                    <span class="title"> Date: </span>
                    <flat-pickr
                      v-model="today"
                      class="form-control invoice-edit-input"
                      disabled
                    />
                  </div>
                </div>
              </div>
            </b-card-body>
            <hr class="invoice-spacing" />

            <b-card-body class="invoice-padding pt-0">
              <b-row class="invoice-spacing">
                <b-col cols="12" xl="6" class="mb-lg-1">
                  <h6 class="mb-2">Client :</h6>
                  <v-select
                    placeholder="Veuillez choisir un client"
                    v-model="customerMultiSelect"
                    :options="customers"
                    label="name"
                    input-id="invoice-data-client"
                    :clearable="false"
                  >
                    <template #list-header>
                      <li
                        v-b-toggle.sidebar-invoice-add-new-customer
                        class="add-new-client-header d-flex align-items-center my-50"
                      >
                        <feather-icon icon="PlusIcon" size="16" />
                        <span class="align-middle ml-25"
                          >Ajouter un client</span
                        >
                      </li>
                    </template>
                  </v-select>

                  <div class="mt-1" v-if="myCustomer.name != ''">
                    <b-card-text class="mb-25"
                      ><b>Nom :</b> {{ myCustomer.name }}
                    </b-card-text>
                    <b-card-text class="mb-25">
                      <b>Adresse :</b> {{ myCustomer.address }}
                    </b-card-text>
                    <b-card-text class="mb-25"
                      ><b>Téléphone :</b> {{ myCustomer.telephone }}
                    </b-card-text>
                    <b-card-text class="mb-0"
                      ><b>Email :</b> {{ myCustomer.email }}</b-card-text
                    >
                  </div>
                </b-col>

                <!-- Col: Payment Details -->
              </b-row>

              <div class="col-md-3" style="float: right">
                <validation-provider
                  #default="{ errors }"
                  :rules="'required'"
                  name="TVA"
                >
                  <b-form-group label="TVA(%)">
                    <b-form-input type="number" v-model="invoice.tva" />
                    <small class="text-danger"> {{ errors[0] }} </small>
                  </b-form-group>
                </validation-provider>
                <validation-provider
                  #default="{ errors }"
                  :rules="'required'"
                  name="Remise"
                >
                  <b-form-group label="Remise(%)">
                    <b-form-input type="number" v-model="invoice.discount" />
                    <small class="text-danger"> {{ errors[0] }} </small>
                  </b-form-group>
                </validation-provider>
              </div>
            </b-card-body>

            <b-card-body class="">
              <div class="mb-2 table-responsive">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Prestation</th>
                      <th scope="col">Prix</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="service in recurring_invoice_services"
                      :key="service.id"
                    >
                      <td>
                        {{ service.name }}
                      </td>
                      <td>
                        {{ service.price }}
                      </td>
                      <td class="d-flex justify-content-between">
                        <span
                          @click="editThisPrestation(service)"
                          class="text-success ky-btn"
                        >
                          <feather-icon icon="EditIcon" />
                        </span>
                        <span
                          @click="deletePrestation(service)"
                          class="text-danger ky-btn"
                        >
                          <feather-icon icon="XIcon" />
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </b-card-body>

            <div class="ml-1 mr-0 justify-content-center">
              <validation-observer ref="serviceForm">
                <b-form class="repeater-form">
                  <b-row ref="row">
                    <b-col md="6">
                      <validation-provider
                        #default="{ errors }"
                        name="Prestation"
                        rules="required"
                      >
                        <b-form-group label="Prestation" label-for="item-name">
                          <b-form-input
                            :disabled="myCustomer.name == '' ? true : false"
                            id="item-name"
                            type="text"
                            v-model="service.name"
                            placeholder="Veuillez saisir la prestation à facturer"
                          />
                          <small
                            v-if="myCustomer.name == ''"
                            class="text-danger"
                          >
                            <feather-icon icon="AlertTriangleIcon" size="14" />
                            Veuillez d'abord sélectionner un client </small
                          ><br />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>

                    <b-col lg="5" md="1">
                      <validation-provider
                        name="Prix"
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-group label="Prix" label-for="price">
                          <b-form-input
                            :disabled="myCustomer.name == '' ? true : false"
                            v-model="service.price"
                            id="price"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>

                    <b-col lg="1" md="2" class="mb-50 mt-0 mt-md-2">
                      <!-- save prestation component -->
                      <b-avatar
                        v-if="isPrestationEditMode == false"
                        :disabled="serviceSaving"
                        button
                        variant="success"
                        @click="saveRecurringInvoiceService()"
                        size="2rem"
                      >
                        <b-spinner v-if="serviceSaving" small />
                        <feather-icon v-else icon="SaveIcon" />
                      </b-avatar>
                      <!-- end save prestation component -->
                      <!-- edit prestation component -->
                      <b-avatar
                        v-if="isPrestationEditMode == true"
                        :disabled="serviceSaving"
                        button
                        variant="success"
                        @click="editPrestation()"
                        size="2rem"
                      >
                        <b-spinner v-if="serviceSaving" small />
                        <feather-icon v-else icon="EditIcon" />
                      </b-avatar>
                      <!-- end edit prestation component -->
                    </b-col>
                    <b-col cols="12">
                      <hr />
                    </b-col>
                  </b-row>
                </b-form>
              </validation-observer>
            </div>

            <b-card-body class="invoice-padding pb-0 mt-2">
              <b-row>
                <b-col
                  cols="12"
                  md="6"
                  class="mt-md-0 mt-3 d-flex align-items-center"
                  order="2"
                  order-md="1"
                >
                </b-col>

                <!-- Col: Total -->
                <b-col
                  cols="12"
                  md="6"
                  class="mt-md-6 d-flex justify-content-end"
                  order="1"
                  order-md="2"
                >
                  <div class="invoice-total-wrapper">
                    <hr class="my-50" />
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">Total HT :</p>
                      <p class="invoice-total-amount">{{ invoiceTotal() }} €</p>
                    </div>
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">TVA:</p>
                      <p class="invoice-total-amount">{{ totalTVA() }} €</p>
                    </div>
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">Remise:</p>
                      <p class="invoice-total-amount">
                        {{ totalDiscount() }} €
                      </p>
                    </div>
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">Total TTC:</p>
                      <p class="invoice-total-amount">{{ invoiceTTC() }} €</p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing" />

            <!-- Note -->
          </b-card>
        </b-card>
      </b-col>

      <b-col cols="12" md="4" xl="3" class="invoice-actions mt-md-0 mt-2">
        <validation-observer ref="recurringInvoiceForm">
          <div class="mt-2">
            <validation-provider
              name="Frequence"
              #default="{ errors }"
              rules="required"
            >
              <b-form-group label="Envoyée par :">
                <b-form-select v-model="invoice.frequence">
                  <b-form-select-option :value="null"
                    >Choisir une option d'envoi</b-form-select-option
                  >
                  <b-form-select-option value="d">Jour</b-form-select-option>
                  <b-form-select-option value="w">Semaine</b-form-select-option>
                  <b-form-select-option value="m">Mois</b-form-select-option>
                  <b-form-select-option value="y">Année</b-form-select-option>
                </b-form-select>
                <small class="text-danger"> {{ errors[0] }} </small>
              </b-form-group>
            </validation-provider>

            <validation-provider
              name="Option"
              #default="{ errors }"
              rules="required"
            >
              <b-form-group
                v-if="invoice.frequence != null"
                :label="'Fréquence'"
              >
                <b-form-input type="number" v-model="invoice.every" />
                <small class="text-danger"> {{ errors[0] }} </small>
              </b-form-group>
            </validation-provider>

            <validation-provider
              name="Date de début"
              #default="{ errors }"
              rules="required"
            >
              <b-form-group label="Date de début :">
                <b-form-input type="date" v-model="invoice.start_date" />
                <small class="text-danger"> {{ errors[0] }} </small>
              </b-form-group>
            </validation-provider>
            <validation-provider
              name="Date de fin"
              #default="{ errors }"
              rules="required"
            >
              <b-form-group label="Date de fin :">
                <b-form-input type="date" v-model="invoice.end_date" />
                <!-- <b-form-input type="date" v-model="invoice.end_date" /> -->
                <small class="text-danger"> {{ errors[0] }} </small>
              </b-form-group>
            </validation-provider>

            <div
              v-if="
                invoice.frequence != null &&
                invoice.every != '' &&
                invoice.start_date != '' &&
                invoice.end_date != ''
              "
            >
              <b-card>
                <h3>
                  <feather-icon icon="AlertTriangleIcon" size="18" />
                  Information
                </h3>
                <hr />
                <p class="lh-lg">
                  Cette facture sera envoyée tous les
                  <b
                    >{{ invoice.every }}
                    {{
                      invoice.frequence == "d"
                        ? "jour(s)"
                        : invoice.frequence == "w"
                        ? "semaine(s)"
                        : invoice.frequence == "m"
                        ? "mois"
                        : "année(s)"
                    }}
                  </b>
                  à compter du <b>{{ invoice.start_date }}</b> jusqu'au
                  <b> {{ invoice.end_date }} </b>.
                </p>
              </b-card>
            </div>
          </div>
          <hr />
          <b-card>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mb-75 d-flex align-items-center justify-content-center"
              block
              :disabled="loading"
              @click="updateRecurringInvoice"
            >
              <b-spinner v-if="loading" type="grow" small></b-spinner>
              Enregistrer
            </b-button>
            <!-- <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="mb-75"
              block
            >
              Prévisualiser
            </b-button> -->
          </b-card>
        </validation-observer>
      </b-col>
    </b-row>
    <invoice-sidebar-add-new-customer />
  </section>
</template>

<script>
import Ripple from "vue-ripple-directive";
import { VBToggle } from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { mapState, mapActions, mapGetters } from "vuex";
import Multiselect from "vue-multiselect";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import InvoiceSidebarAddNewCustomer from "./InvoiceSidebarAddNewCustomer.vue";

export default {
  data() {
    var today = new Date();
    const now = today;
    const myDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const minDate = new Date(myDate);
    minDate.setMonth(0);
    minDate.setYear(minDate.getFullYear());
    minDate.setDate(1);

    const maxDate = new Date();
    maxDate.setMonth(11);
    maxDate.setYear(maxDate.getFullYear());
    maxDate.setDate(31);

    return {
      min: minDate,
      max: maxDate,
      loading: false,
      errors: "",
      isPrestationEditMode: false,
      theSum: 0,
      serviceSaving: false,
      thisRecurringInvoice: {},
      isDisable: false,
      enterprise: {},
      today,
      customerMultiSelect: null,
      total: null,
      myCustomer: {
        name: "",
        address: "",
        telephone: 0,
        email: "",
      },
      invoice: {
        id: 0,
        num: "",
        customer_id: "",
        services: {},
        total: 0,
        tva: 20,
        discount: 0.0,
        frequence: null,
        every: "",
        start_date: "",
        end_date: "",
      },
      service: {
        id: 0,
        recurring_invoice_id: 0,
        name: "",
        price: "",
      },

      serviceDefault: {
        id: 0,
        recurring_invoice_id: 0,
        name: "",
        price: "",
      },

      nextTodoId: 2,
      clients: {},
    };
  },

  components: {
    flatPickr,
    vSelect,
    Multiselect,
    ToastificationContent,
    InvoiceSidebarAddNewCustomer,
  },

  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },

  computed: {
    ...mapState("enterprise", { customers: (state) => state.clients }),
    ...mapState("tasks", [
      "recurring_invoice_services",
      "lastrecurringinvoiceNum",
    ]),
    ...mapGetters("tasks", ["varGetLastRecurringInvoiceNum"]),
  },
  watch: {
    customerMultiSelect(val) {
      this.myCustomer = this.customerMultiSelect;
      if (this.myCustomer.name != "") {
        this.invoice.customer_id = this.myCustomer.id;
        this.createRecurringInvoice(this.invoice).then((response) => {
          this.thisRecurringInvoice = Object.assign(response.data);
        });
      }
    },
    lastrecurringinvoiceNum(val) {
      if (val) {
        this.invoice.num = "R" + val.id;
      }
    },
  },

  mounted() {
    this.getInfo().then((r) => {
      this.enterprise = r.data.data;
    });
    this.invoice.num = "R" + this.lastrecurringinvoiceNum.id;

    this.fetchCustomers();
    this.getLastRecurringInvoiceNum();
  },

  methods: {
    ...mapActions("enterprise", ["fetchCustomers", "addCustomer"]),
    ...mapActions("enterprise", ["getInfo"]),
    ...mapActions("tasks", [
      "getLastRecurringInvoiceNum",
      "createRecurringInvoice",
      "createRecurringInvoiceService",
      "fetchRecurringInvoiceServices",
      "deleteRecurringInvoicePrestation",
      "editRecurringInvoicePrestation",
      "editRecurringInvoice",
    ]),

    goBack() {
      history.back();
    },

    editThisPrestation(data) {
      this.service = data;
      this.isPrestationEditMode = true;
    },

    deletePrestation(data) {
      this.deleteRecurringInvoicePrestation(data.id).then((res) => {
        this.fetchRecurringInvoiceServices(this.thisRecurringInvoice.num);
      });
    },

    invoiceTotal() {
      var sum = 0;
      for (
        let item = 0;
        item < this.recurring_invoice_services.length;
        item++
      ) {
        if (this.recurring_invoice_services[item].price == null) {
          return "0";
        } else {
          sum += Number(this.recurring_invoice_services[item].price);
        }
      }
      (this.total = sum),
        (this.theSum = sum - (sum * this.invoice.discount) / 100);
      return sum
        .toFixed(0)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },

    totalDiscount() {
      // calculate the total discount
      var discount = this.invoice.discount;
      var total = this.total;
      var discountAmount = (total * discount) / 100;
      return discountAmount
        .toFixed(0)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },

    totalTVA() {
      // calculate the total tva
      var tva = this.invoice.tva;
      var total = this.total;
      var tvaAmount = (total * tva) / 100;
      return tvaAmount
        .toFixed(0)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },

    invoiceTTC() {
      var myTTC = this.theSum + (this.theSum * this.invoice.tva) / 100;
      return myTTC
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },

    customerName({ name }) {
      return name;
    },

    async updateRecurringInvoice() {
      const valid = await this.$refs.recurringInvoiceForm.validate();
      if (!valid) return;
      this.loading = true;
      this.editRecurringInvoice(this.$formData(this.invoice))
        .then((res) => {
          this.$router.push("/admin/recurring-invoices");
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Facture créer avec succès",
              icon: "AlertCircleIcon",
              variant: "success",
            },
          });
        })
        .catch((err) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Erreur de validation",
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          });
        });
    },

    async saveRecurringInvoiceService() {
      const valid = await this.$refs.serviceForm.validate();
      if (!valid) return;
      this.serviceSaving = true;
      this.service.recurring_invoice_id = this.thisRecurringInvoice.id;
      this.createRecurringInvoiceService(this.$formData(this.service)).then(
        (res) => {
          this.service.id = 0;
          this.service.name = "";
          this.service.price = "";
          this.service.recurring_invoice_id = 0;
          this.$refs.serviceForm.reset();
          this.serviceSaving = false;
        }
      );
    },

    editPrestation() {
      this.editRecurringInvoicePrestation(this.$formData(this.service)).then(
        (res) => {
          this.clearForm();
          this.isPrestationEditMode = false;
        }
      );
    },

    clearForm() {
      this.service = this.serviceDefault;
      this.$nextTick().then((response) => {
        this.$refs.serviceForm.reset();
      });
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css">
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

// .v-select {
//   &.item-selector-title,
//   &.payment-selector {
//     background-color: #fff;

//     .dark-layout & {
//       background-color: unset;
//     }
//   }
// }

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}

.ky-btn {
  cursor: pointer;
}
</style>
