<template>
  <b-sidebar id="sidebar-invoice-add-new-customer" ref="mySideBar" sidebar-class="sidebar-lg" bg-variant="white" shadow
    backdrop no-header right>
    <template #default="{ hide }">
      <!-- Header -->
      <div class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        ">
        <h5 class="mb-0">Add Customer</h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>

      <b-card>
        <h4>
          <feather-icon icon="UserPlusIcon" size="18" />
          Nouveau client
        </h4>
        <hr />
        <validation-observer ref="addCustomerForm">
          <b-media-body class="text-center">
            <!-- +++++++++++ -->
            <b-avatar class="mb-3" size="7em" :src="imageUrl || client.logo" :icon="'image'">
              <template #badge>
                <input accept="image/*" type="file" hidden id="logo" @change="onFileUpload" />
                <b-icon @click="openFilePicker" style="cursor: pointer" icon="camera"></b-icon>
              </template>
            </b-avatar>
            <!-- +++++++++++ -->
          </b-media-body>
          <b-form>
            <validation-provider rules="required" name="Nom du client" #default="{ errors }">
              <b-form-group label="Nom du client">
                <b-form-input placeholder="..." v-model="client.name" />
              </b-form-group>
              <small class="text-danger"> {{ errors[0] }} </small>
            </validation-provider>

            <validation-provider :rules="isEmailRequired" name="Email du client" #default="{ errors }">
              <b-form-group label="Email du client">
                <b-form-input type="email" placeholder="..." v-model="client.email" />
              </b-form-group>
              <small class="text-danger"> {{ errors[0] }} </small>
            </validation-provider>

            <validation-provider :rules="isTelephoneRequired" name="N° de téléphone du client " #default="{ errors }">
              <b-form-group label="N° de téléphone du client ">
                <b-form-input type="number" placeholder="..." v-model="client.telephone" />
              </b-form-group>
              <small class="text-danger"> {{ errors[0] }} </small>
            </validation-provider>

            <validation-provider name="Adresse du client" #default="{ errors }">
              <b-form-group label="Adresse du client ">
                <b-form-input type="text" placeholder="..." v-model="client.address" />
              </b-form-group>
              <small class="text-danger"> {{ errors[0] }} </small>
            </validation-provider>

            <validation-provider name="N° matricule du client" #default="{ errors }">
              <b-form-group label="N° matricule du client ">
                <b-form-input type="text" placeholder="..." v-model="client.matricule" />
              </b-form-group>
              <small class="text-danger"> {{ errors[0] }} </small>
            </validation-provider>

            <validation-provider name="Site du client" #default="{ errors }">
              <b-form-group label="Site du client ">
                <b-form-input type="text" placeholder="ex: https://ekoworking.com" v-model="client.website" />
              </b-form-group>
              <small class="text-danger"> {{ errors[0] }} </small>
            </validation-provider>



            <div class="text-center">
              <b-row>

                <b-col>
                  <b-button block @click="addThisCustomer()">Enregistrer</b-button>
                </b-col>
              </b-row>
            </div>
          </b-form>
        </validation-observer>
      </b-card>

      <!-- Body -->
    </template>
  </b-sidebar>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { ref } from "@vue/composition-api";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import KyInput from "@/@core/layouts/components/ky-input.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    vSelect,
    KyInput,
    ToastificationContent
  },
  directives: {
    Ripple,
  },
  setup() {
    return {
      errors: "",
      client: {
        id: "",
        name: "",
        telephone: "",
        logo: "",
        email: "",
        website: "",
        matricule: "",
        address: "",
      },
      clientDefault: {
        id: "",
        name: "",
        telephone: "",
        logo: "",
        email: "",
        website: "",
        matricule: "",
        address: "",
      },
      uploaded: false,
      imageUrl: null,
    };
  },
  computed: {
    ...mapState("enterprise", { customers: (state) => state.clients }),

    isEmailRequired() {
      if (this.client.telephone == "") {
        return "required";
      }
    },
    isTelephoneRequired() {
      if (this.client.email == "") {
        return "required";
      }
    },
  },

  mounted() {
    this.fetchCustomers();
  },

  methods: {
    ...mapActions("enterprise", ["fetchCustomers", "addCustomer", "getInfo"]),

    onFileUpload(event) {
      this.uploaded = true;
      var file = event.target.files[0];
      if (file) this.imageUrl = URL.createObjectURL(file);

      this.client.logo = file;
      this.client.logo_only = 1;
    },
    openFilePicker() {
      document.getElementById("logo").click();
    },

    async addThisCustomer() {
      const valid = await this.$refs.addCustomerForm.validate();
      if (!valid) return;
      this.showLoading = true;
      this.addCustomer(this.$formData(this.client)).then((r) => {
          this.showLoading = false;
          this.$refs.mySideBar.hide();
          this.client.name = "";
          this.client.telephone = "";
          this.client.logo = "";
          this.client.email = "";
          this.client.website = "";
          this.client.matricule = "";
          this.client.address = "";
          this.$refs.addCustomerForm.reset();
          this.fetchCustomers();

          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Client ajouter avec succès",
              icon: "AlertCircleIcon",
              variant: "success",
            },
          });
        }).catch((error) => {
          this.showLoading = false;
          this.errorMessage = error;
          this.errors = error.response;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.response.data.message,
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          });
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
