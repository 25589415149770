var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{ref:"mySideBar",attrs:{"id":"sidebar-invoice-add-new-customer","sidebar-class":"sidebar-lg","bg-variant":"white","shadow":"","backdrop":"","no-header":"","right":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"\n        d-flex\n        justify-content-between\n        align-items-center\n        content-sidebar-header\n        px-2\n        py-1\n      "},[_c('h5',{staticClass:"mb-0"},[_vm._v("Add Customer")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('b-card',[_c('h4',[_c('feather-icon',{attrs:{"icon":"UserPlusIcon","size":"18"}}),_vm._v(" Nouveau client ")],1),_c('hr'),_c('validation-observer',{ref:"addCustomerForm"},[_c('b-media-body',{staticClass:"text-center"},[_c('b-avatar',{staticClass:"mb-3",attrs:{"size":"7em","src":_vm.imageUrl || _vm.client.logo,"icon":'image'},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('input',{attrs:{"accept":"image/*","type":"file","hidden":"","id":"logo"},on:{"change":_vm.onFileUpload}}),_c('b-icon',{staticStyle:{"cursor":"pointer"},attrs:{"icon":"camera"},on:{"click":_vm.openFilePicker}})]},proxy:true}],null,true)})],1),_c('b-form',[_c('validation-provider',{attrs:{"rules":"required","name":"Nom du client"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Nom du client"}},[_c('b-form-input',{attrs:{"placeholder":"..."},model:{value:(_vm.client.name),callback:function ($$v) {_vm.$set(_vm.client, "name", $$v)},expression:"client.name"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)}),_c('validation-provider',{attrs:{"rules":_vm.isEmailRequired,"name":"Email du client"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Email du client"}},[_c('b-form-input',{attrs:{"type":"email","placeholder":"..."},model:{value:(_vm.client.email),callback:function ($$v) {_vm.$set(_vm.client, "email", $$v)},expression:"client.email"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)}),_c('validation-provider',{attrs:{"rules":_vm.isTelephoneRequired,"name":"N° de téléphone du client "},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"N° de téléphone du client "}},[_c('b-form-input',{attrs:{"type":"number","placeholder":"..."},model:{value:(_vm.client.telephone),callback:function ($$v) {_vm.$set(_vm.client, "telephone", $$v)},expression:"client.telephone"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Adresse du client"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Adresse du client "}},[_c('b-form-input',{attrs:{"type":"text","placeholder":"..."},model:{value:(_vm.client.address),callback:function ($$v) {_vm.$set(_vm.client, "address", $$v)},expression:"client.address"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)}),_c('validation-provider',{attrs:{"name":"N° matricule du client"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"N° matricule du client "}},[_c('b-form-input',{attrs:{"type":"text","placeholder":"..."},model:{value:(_vm.client.matricule),callback:function ($$v) {_vm.$set(_vm.client, "matricule", $$v)},expression:"client.matricule"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Site du client"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Site du client "}},[_c('b-form-input',{attrs:{"type":"text","placeholder":"ex: https://ekoworking.com"},model:{value:(_vm.client.website),callback:function ($$v) {_vm.$set(_vm.client, "website", $$v)},expression:"client.website"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)}),_c('div',{staticClass:"text-center"},[_c('b-row',[_c('b-col',[_c('b-button',{attrs:{"block":""},on:{"click":function($event){return _vm.addThisCustomer()}}},[_vm._v("Enregistrer")])],1)],1)],1)],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }